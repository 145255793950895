@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
    outline: none;
}

@mixin btn-loading {
    position: relative;
    // font-size: 0;
    color: transparent !important;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        background-image: url('../img/loading1.gif');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

body {
    overflow-y: scroll;
}

.hidden {
    display: none !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #eb0601 #f1f1f1;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
    background-color: #eb0601;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

* {
    font-family: "poppins", Arial, Helvetica, sans-serif;
}

.map {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8f8f8f;

    span {
        color: #2e2e2e;
    }
}

.n-item {
    display: flex;
    margin-left: 45px;
    align-items: center;

    &>img {
        height: 37px;
        filter: invert(1);
        margin-right: 10px;
    }

    .content {
        .t {
            color: #eb0601;
            font-size: 14px;
            display: block;
        }

        .b {
            color: #fff;
            font-size: 14px;
            display: block;
            position: relative;

            a {
                color: #fff;
            }

            img {
                height: 20px;
                filter: invert(1);
            }

            .dropdown-menu {
                right: 0;

                a {
                    color: #000;
                }
            }

            &:hover {
                .dropdown-menu {
                    display: block;
                }
            }
        }
    }
}

.top-nav {
    background: #0c0e12;
    display: flex;
    padding-top: 26px;
    padding-bottom: 26px;

    .left {
        img {
            height: auto;
            width: 270px;
            max-width: 90%;
        }
    }

    .mid {
        flex-grow: 1;
        padding: 0 5%;
        max-width: 700px;
        margin: auto;
    }

    .right {
        display: flex;

        .n-item:nth-child(1) {
            margin-left: 0 !important;
        }
    }

    .container {
        font-size: 12px;
        font-weight: 600;
    }

    a>span {
        margin-right: 5px;
    }

    .search-form {
        display: flex;
        width: 100%;
        border-radius: 7px;

        input {
            flex-grow: 1;
            padding: 10px 15px;
            background: #262626;
            border: none;
            height: 46px;
            font-size: 19px;
            color: #fff;
            border: 2px solid rgba(233, 63, 51, 0.3);
            border-right: none;
            border-radius: 7px 0 0 7px;
            transition: .3s all;

            &::placeholder {
                color: rgba($color: #fff, $alpha: .3);
            }
        }

        button {
            flex-shrink: 0;
            border: none;
            background: #eb0601;
            color: #fff;
            height: 46px;
            width: 72px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 7px 7px 0;

            img {
                height: 20px;
                filter: invert(1);
            }
        }

        &:focus-within {
            input {
                border-color: #eb0601;
                background: #313131;
            }
        }
    }
}

.bot-nav {

    .navbar-dark {
        min-height: 53px;
        background: #11141b;
    }

    .navbar-dark .navbar-nav .nav-link {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
    }

    .dropdown-toggle::after {
        content: "";
        border: none;
    }

    .n-item {
        margin-left: 25px;
        text-decoration: none;

        img {
            height: 20px;
        }

        .b {
            font-size: 17px;
        }
    }

    .right {
        display: flex;

        .n-item:nth-child(1) {
            margin-left: 0 !important;
            border-right: 1px solid #444;
            padding-right: 25px;
        }
    }
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-item {
    cursor: pointer;
}

/* Home */

.find-form {
    border: 1px solid #ddd;
    border-radius: 45px;
    padding: 0;
    max-width: 850px;
    overflow: hidden;
    margin: 15px auto !important;
    margin-bottom: 50px !important;

    select {
        padding: 13px 10px;
        width: 100%;
        border: none;
        background: none;
        border-right: 1px solid #ddd;
    }

    button {
        width: 100% !important;
        background: none;
        color: #fff;
        border: none;
        height: 100%;
        font-size: 15px;
        font-weight: bold;
    }

    .col-md-3 {
        padding: 0;
    }

    .col-md-3:last-child {
        padding: 0 !important;
        background: #eb0601;
        color: #fff;
        display: flex;
        align-items: center;
    }
}

.imghome {
    height: 450px;
    background-color: #171717;

    &::after {
        content: '';
        background: linear-gradient(45deg, black, rgba(0, 0, 0, 0.388), #000);
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: absolute;
        z-index: 1;
    }
}

.description {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 10%;
    color: #fff;
    text-align: right;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    .desc1 {
        font-weight: bold;
        font-size: 30px;
        text-align: left;
        color: #eb0601;
    }

    .desc2 {
        font-weight: 600;
        max-width: 450px;
        font-size: 40px;
        text-align: left;
        line-height: 1.3;
        margin-bottom: 35px;
    }

    .btn-home {
        background: #eb0601;
        border-radius: 2.5px;
        color: #fff;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        border-radius: 7px !important;
        width: auto;
        justify-self: start;
    }
}

.service {
    position: relative;
    min-height: 115px;
    width: 100%;
    margin-top: 25px;

    .linet {
        position: absolute;
        width: 100%;
        height: 2px;
        background: #d6d6d6;
        width: 100%;
        top: 1%;
        left: 0;
    }

    .lineb {
        position: absolute;
        width: 100%;
        height: 2px;
        background: #d6d6d6;
        width: 100%;
        bottom: 3%;
        left: 0;
    }

    .liner {
        position: absolute;
        right: 1%;
        top: -2%;
        background: #d6d6d6;
        width: 2px;
        height: 102%;
    }

    .linel {
        position: absolute;
        left: 1%;
        top: -2%;
        background: #d6d6d6;
        width: 2px;
        height: 102%;
    }
}


.service-child {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    padding: 24px;

    .child {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.des-icon {
    text-align: left;
    margin-left: 25px;

    p {
        margin: 0;
        padding: 0;

        &:nth-child(1) {
            font-weight: 700;
        }
    }
}

.book {
    background-image: url("../img/Rectangle15.png");
    background-repeat: no-repeat;
    margin-top: 50px;
    color: #fff;
    margin-bottom: 50px;
    background-size: cover;
    min-height: 300px;
    text-align: center;

    .container {
        padding-top: 50px;
        padding-bottom: 30px;
        text-align: center;
    }
}

.book-modal .container {
    padding-top: 50px;
    padding-bottom: 30px;
    text-align: center;
}

.book .container>p {
    margin-bottom: 60px;
}

.form-group {
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;

    select {
        height: 60px;
        border-radius: 0;
    }
}

.book button,
.book-modal button {
    height: 60px;
    border-radius: 0;
}

.form-btn {
    padding-left: 1.7rem;
}

.book button,
.book-modal button {
    background-color: #eb0601;
    color: #fff;
    display: block;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.modal.show {
    display: block !important;
}

/* Slide */

.new-btn {
    font-size: 10px;
    cursor: auto;
}

.product-des {
    font-weight: 500;
    font-size: 15px;
    color: #767676;
    height: 45px;
    overflow: hidden;
    display: block;
}

.product-brand {
    display: block;
    color: #eb0601;
    font-weight: bold;
    font-size: 14px;
}

.product-item {
    display: inline-block;
    max-width: 300px;

    .card-header {
        text-align: left !important;
    }

    a {
        text-decoration: none;
    }

    img {
        display: block;
        margin: auto;
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: center;
        border-radius: 12px;

    }

    .price {
        background-color: #ededed;
        font-size: 14px;
        font-weight: 600;
        padding: 5px 1.2rem;
    }

    .addtocardbtn {
        height: 100%;
        font-size: 12px;
        background-color: #000;
        color: #fff;
        clip-path: polygon(0 0, 100% 0, 85% 100%, 15% 100%);
        left: -20px;
        position: relative;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        transition: all 0.2ms;

        &:hover {
            background-color: #eb0601;
            color: #fff;
        }
    }

    .first-price {
        color: #767676;
        font-size: 10px;
        -webkit-text-decoration-line: line-through;

        /* Safari */
        text-decoration-line: line-through;
    }

    .des-price {
        color: #eb0601;
    }

    .fav-btn {
        border: none;
        background: none;
        position: absolute;
        bottom: 17px;
        right: 14px;

        &.active {

            svg,
            path {
                fill: #eb0601 !important;
                stroke: #eb0601 !important;
            }
        }
    }
}

.card-footer {
    height: 30px;

    span svg {
        cursor: pointer;
    }
}

.slide .card {
    margin: 10px auto;
}

.card {
    border-radius: 12px !important;
}

.slider h2 {
    text-align: center;
}

.buttons-prev-next {
    margin-right: 10px;

    .slick-next,
    .slick-prev {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px;
        transform: translate(0, 0);

        svg {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
        }

        &::before {
            content: none;
        }
    }

    span:first-child {
        margin-right: 15px;
    }
}

.slider .slick-slider>button {
    display: none !important;
}

/* Counter until footer */

.counter-wheel {
    flex: 73%;
    background: rgb(242, 242, 242);
    // background-image: url("../img/sale-bg.gif");
    // background-size: 250px 250px;

    .w-img {
        width: 100%;
        position: relative;
        padding-top: 90%;
        border-radius: 17px;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }
}

.title,
.price-now {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.055em;
    text-transform: uppercase;
}

.title {
    color: #4b4b4b;
}

.price-now {
    color: #ff0000;
}

.old-price {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.03em;
    text-decoration-line: line-through;
    color: #8f8f8f;
}

.des {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #8f8f8f;
}

.count-num {
    width: 60px;
    height: 80px;
    border: 1px solid #8f8f8f;
    margin-right: 27px;
    text-align: center;

    span {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.03em;
        color: #000000;
    }

    h3 {
        margin: 15px 0px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.03em;
        color: #8f8f8f;
    }
}

.red-btn {
    width: 197px;
    height: 44px;
    border: none;
    background: #eb0601;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: #ffffff !important;
    margin-right: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.small {
    width: 120px;
}

.like {
    width: 44px;
    height: 44px;
    background: #100e0e;
    border: none;
}

.red-btn-up {
    top: -5%;
    left: 5%;
}

.sale {
    flex: 25%;
    height: 453px;
}

.rec {
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    transform: translateY(2%);
    transition: 1s all;
}

.sale {
    h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #ff0000;
        padding-bottom: 7px;
    }

    h2 {
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #000000;
        padding-bottom: 7px;
    }
}

.tool1 {
    img {
        display: block;
        margin: auto;
        margin-top: 15px;
    }

}

.tool2 {
    top: 100%;
    right: -20%;
}

/* /////////////////////////////// */

.newToShope {
    flex: 48%;
    height: 240px;

    &>img {
        object-fit: cover;
        object-position: center;
    }
}

.back {
    background: radial-gradient(56.34% 56.34% at 50% 50%, rgba(255, 255, 255, 0.2) 27.48%, rgba(0, 0, 0, 0) 100%), linear-gradient(90deg, #171717 0%, #2e2e2e 100%);
}

.titleShop {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.055em;
    color: #ff0000;
}

.nameproduct {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: #ffffff;
}

.desshope {
    width: 70%;
}

/* //////////////////////////////////////// */

.freatured-brand,
.brand {
    flex: 49%;
    line-height: 39px;
    letter-spacing: 0.015em;
    color: #000000;
}

.freatured-brand {
    h2 {
        font-weight: 700;
        font-size: 26px;
    }

    h3 {
        font-weight: 500;
        font-size: 24px;
    }

    .line {
        display: block;
        width: 35px;
        height: 3px;
        background-color: #eb0601;
    }

    p {
        font-weight: 400;
        font-size: 18px;
        color: #2e2e2e;
        line-height: 1.4;
        margin-top: 14px;
    }
}

/* /////////////////////////////////// */

.offers {
    background-color: #eb0601;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 0.015em;

    h2 {
        font-size: 28px;
        line-height: 42px;
    }

    h3 {
        font-size: 22px;
        line-height: 33px;
    }

    h4 {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }
}

.gray-btn {
    width: 151px;
    height: 54px;
    background: #4b4b4b;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.075em;
    color: #ffffff;
    border: none;
}

.inputemail {
    width: 70%;

    &::placeholder {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.015em;
        color: #767676;
    }
}

.buildimg {
    top: -50%;
    right: 0;
}

.our-company {
    flex: 40%;
}

.our-survice {
    flex: 60%;
}

.footer-phone {
    flex: 37%;
}

.our-company p {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.015em;
    color: #4b4b4b;
    width: 320px;
}

.our-survice {
    div {
        a {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.015em;
        }

        &:first-child a {
            padding-left: 40px;
            color: #8f8f8f;
        }

        &:nth-child(2) a {
            color: #8f8f8f;
            padding-right: 40px;
        }
    }

    .info div {
        color: #676767;
    }
}

.footer .info {
    font-size: 14px;
    font-weight: 500;
}

.footer-bot {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.015em;
    color: #2e2e2e;
}

.fit-contain {
    object-fit: contain;
    object-position: center;
}

.products-box {
    display: flex;
    flex-wrap: wrap;

    .product-item {
        width: 49%;
        margin-right: 1%;
        margin-bottom: 15px;
    }
}

.imgs-slider {
    .slider-nav {
        .img {
            width: 100%;
            padding-top: 100%;
            position: relative;
            opacity: 0.8;
            transition: 0.3s all;
            transform: scale(0.9);
            cursor: pointer;

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 80%;
                height: 80%;
                margin: auto;
                object-fit: contain;
            }
        }

        .slick-current {
            background: #ddd;

            .img {
                opacity: 1;
                transform: scale(0.9);
            }
        }
    }

    .slider-single {
        .img {
            width: 100%;
            padding-top: 60%;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 80%;
                height: 80%;
                margin: auto;
                object-fit: contain;
            }
        }
    }

}

form.is-loading {
    position: relative;

    button[type=submit] {
        @include btn-loading()
    }

}

button.is-loading {
    @include btn-loading();

    svg {
        opacity: 0;
    }

    &::after {
        background-image: url('../img/loading3.gif');
    }
}

.dropdown-menu.is-loading {
    @include btn-loading();
    position: absolute;
    pointer-events: all;

    a {
        color: #fff !important;
    }

    &::after {
        background-image: url('../img/loading3.gif');
        background-size: 43px
    }
}

.number.is-loading {
    @include btn-loading();

    &::after {
        background-image: url('../img/loading3.gif');
    }
}

input:disabled {
    opacity: 0.8;
}

.options-box {
    height: 400px;
    overflow-y: auto;

    .option-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin: 6px 0;

        .left {
            width: 100%;
            display: flex;
            align-items: center;

            input {
                flex-shrink: 0;
            }

            span {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

        }
    }

}

.empty-box {
    width: 100%;
    display: block;
    text-align: center;
    color: #8F8F8F;
    margin: 50px 0;

    img,
    svg {
        display: block;
        margin: auto;
        margin-bottom: 15px;
    }
}

.added-modal {

    div.left {
        min-height: 100%;
        display: flex;
        border-right: 1px solid #ddd;

        .m-product-img {
            width: 120px;
            height: 120px;
            object-fit: cover;
            object-position: center;
            margin-right: 10px;
        }

        .content {
            .name {
                display: block;
                font-weight: 500;
                font-size: 16px;
                line-height: 1.2;
                margin-bottom: 10px;
            }

            .op1 {
                font-size: 15px;
                display: block;
            }
        }
    }
}