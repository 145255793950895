// .js .slider-single {
//     >div:nth-child(1n + 2) {
//         display: none;
//     }

//     &.slick-initialized>div:nth-child(1n + 2) {
//         display: block;
//     }
// }

// .item-slider {
//     background: #f0f0f0;
//     color: #3498db;
//     font-size: 2.25rem;
//     margin: 0.5rem;
//     padding: 2%;
//     position: relative;
//     text-align: center;
// }

// .slider-single .slick-list {
//     height: 427px !important;
// }

.slick-track {
    height: 100%;
}

.slider-img {
    border: 1px solid #e6e6e6;
}

.slider-single>button {
    display: none !important;
}

// .slider-nav {
//     h3 {
//         &::before {
//             content: "";
//             display: block;
//             padding-top: 75%;
//         }

//         span {
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             transform: translate(-50%, -50%);
//         }
//     }

//     .slick-slide {
//         cursor: pointer;
//     }
// }

.slick-slide.is-active h3 {
    color: #c00;
    background-color: #fff;
}

.slick-prev:before,
.slick-next:before {
    color: black;
}